import React from 'react';
import {
  ComponentPreview,
  ComponentWithLabel,
  PageHero,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from 'docComponents';
import { AvatarOrg } from 'hudl-uniform-ui-components';
import SectionUsage from '../partials/_SectionUsage';
import SectionPlatformsDesign from '../partials/_SectionPlatforms';
import SectionPageNav from '../partials/_SectionPageNav';
import avatarPreviewData from '../../../../data/previews/avatar.yml';
import pageHeroData from '../../../../data/pages/components.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Org Avatar"
      designCode
      subnav="components">
      <PageHero heroData={pageHeroData} tierTwo="Avatars" tierThree="Org" />

      <SectionPageNav />

      <Section title="Style">
        <Paragraph>
          The avatar’s style depends on the content available.
        </Paragraph>
        <ComponentPreview
          name="AvatarStyle"
          previewData={avatarPreviewData.orgTypes}>
          <AvatarOrg size="xlarge" />
        </ComponentPreview>
      </Section>

      <Section title="Sizes">
        <Paragraph>
          Consider layout density and view hierarchy when choosing an avatar’s
          size.
        </Paragraph>
        <ComponentPreview
          name="AvatarSize"
          layout="split"
          previewData={avatarPreviewData.sizes}>
          <AvatarOrg size="xsmall" />
        </ComponentPreview>

        <SectionSubhead>Custom Sizes</SectionSubhead>
        <Paragraph>
          There are some cases when an additional size may be necessary and a
          custom size can be used. The only custom size currently available (for
          arguably the biggest use case) is profile.
        </Paragraph>
        <ComponentPreview name="CustomSize">
          <ComponentWithLabel labelText="Profile">
            <AvatarOrg size="profile" />
          </ComponentWithLabel>
        </ComponentPreview>
      </Section>

      <SectionUsage />

      <SectionPlatformsDesign />
    </PageWithSubNav>
  );
};

export default IndexPage;
