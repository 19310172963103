import React from 'react';
import { PlatformTable, PlatformTableRow, Section } from 'docComponents';

const SectionPlatformsDesign = () => {
  return (
    <Section title="Platforms">
      <PlatformTable footerMessage="No guidelines exist for Windows (yet).">
        <PlatformTableRow platform="web" />
        <PlatformTableRow platform="apple" />
        <PlatformTableRow platform="android" />
      </PlatformTable>
    </Section>
  );
};

export default SectionPlatformsDesign;
