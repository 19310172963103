import React from 'react';
import {
  DontDo,
  DontDoItem,
  Paragraph,
  Section,
  SectionSubhead,
} from 'docComponents';

const SectionUsage = () => {
  return (
    <Section title="Usage">
      <SectionSubhead>Borders</SectionSubhead>
      <DontDo gridLayout="2">
        <DontDoItem
          type="dont"
          text="add borders where several avatars appear together."
          img="avatar-borders-dont"
        />
        <DontDoItem
          type="do"
          text="use a border to ground an avatar in a spacious area."
          img="avatar-borders-do"
        />
      </DontDo>

      <SectionSubhead>Linked Avatars</SectionSubhead>
      <Paragraph>
        Don’t give the avatar a link that doesn’t make sense, like pizzahut.com.
        The user avatar should go to a user’s profile, not the team’s, and vice
        versa.
      </Paragraph>
    </Section>
  );
};

export default SectionUsage;
