import React from 'react';
import { PageNavigation, PageNavigationLink } from 'docComponents';

const SectionPageNav = () => {
  return (
    <PageNavigation>
      <PageNavigationLink>Style</PageNavigationLink>
      <PageNavigationLink>Sizes</PageNavigationLink>
      <PageNavigationLink>Usage</PageNavigationLink>
      <PageNavigationLink>Platforms</PageNavigationLink>
    </PageNavigation>
  );
};

export default SectionPageNav;
